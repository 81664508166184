import * as React from "react"
import {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import Menu from "../components/menu"
import "../css/main.scss"
import StandardHeadContent from "../components/standardHeadContent";
import Cookies from "universal-cookie";
import Axios from "axios";
import {StaticImage} from "gatsby-plugin-image";


const PhotoPage = () => {
    const cookies = new Cookies();
    const [lang,setLang] = useState(cookies.get("lang") ||"no");

    const changeLang = (lang) => {
        cookies.set("lang",lang);
        setLang(lang);
    }

    useEffect(() => {
        Axios.get("/log.php?page=photo_"+lang);
    },[])

    const images = [1,26,41,45,49,71,138,140,141,153,155,159,166,168,179,184,186,190];

    return (
        <main>
            <Helmet>
                <title>Nina & Knut - 11. juli 2022</title>
                <StandardHeadContent/>
            </Helmet>

            <Menu lang={lang} changeLang={changeLang}/>

            <div id="duogram"></div>

            {lang != "en" &&
                <>
                    <h1>Bildegalleri</h1>
                    <p>Her er et knippe bilder fra bryllupsfeiringen:</p>

                </>
            }
            {lang == "en" &&
                <>
                    <h1>Photo gallery</h1>
                    <p>
                        Here are a few photos from the wedding celebration:
                    </p>
                </>
            }

            <div>
                <pre>
                {
                    [1,26,41,45,49,71,138,140,141,153,155,159,166,168,179,184,186,190].map((image,i) => {
                        //console.log(image);
                        //return(<pre key={i}> src={"../images/gallery/Nina&KnutHighlights-"+image+".jpg"} alt={"Nina & Knut"}</pre>);
                    })
                }
                </pre>
                <StaticImage src={""} alt={"Nina & KNut"}/>
                <StaticImage src={"../images/gallery/Nina&KnutHighlights-1.jpg"} alt={"Nina & KNut"}/>
                <StaticImage src={"../images/gallery/Nina&KnutHighlights-26.jpg"} alt={"Nina & KNut"}/>
                <StaticImage src={"../images/gallery/Nina&KnutHighlights-41.jpg"} alt={"Nina & KNut"}/>
                <StaticImage src={"../images/gallery/Nina&KnutHighlights-45.jpg"} alt={"Nina & KNut"}/>
                <StaticImage src={"../images/gallery/Nina&KnutHighlights-49.jpg"} alt={"Nina & KNut"}/>
                <StaticImage src={"../images/gallery/Nina&KnutHighlights-71.jpg"} alt={"Nina & KNut"}/>
                <StaticImage src={"../images/gallery/Nina&KnutHighlights-138.jpg"} alt={"Nina & KNut"}/>
                <StaticImage src={"../images/gallery/Nina&KnutHighlights-140.jpg"} alt={"Nina & KNut"}/>
                <StaticImage src={"../images/gallery/Nina&KnutHighlights-141.jpg"} alt={"Nina & KNut"}/>
                <StaticImage src={"../images/gallery/Nina&KnutHighlights-153.jpg"} alt={"Nina & KNut"}/>
                <StaticImage src={"../images/gallery/Nina&KnutHighlights-155.jpg"} alt={"Nina & KNut"}/>
                <StaticImage src={"../images/gallery/Nina&KnutHighlights-159.jpg"} alt={"Nina & KNut"}/>
                <StaticImage src={"../images/gallery/Nina&KnutHighlights-166.jpg"} alt={"Nina & KNut"}/>
                <StaticImage src={"../images/gallery/Nina&KnutHighlights-168.jpg"} alt={"Nina & KNut"}/>
                <StaticImage src={"../images/gallery/Nina&KnutHighlights-179.jpg"} alt={"Nina & KNut"}/>
                <StaticImage src={"../images/gallery/Nina&KnutHighlights-184.jpg"} alt={"Nina & KNut"}/>
                <StaticImage src={"../images/gallery/Nina&KnutHighlights-186.jpg"} alt={"Nina & KNut"}/>
                <StaticImage src={"../images/gallery/Nina&KnutHighlights-190.jpg"} alt={"Nina & KNut"}/>
            </div>

        </main>
    )
}

export default PhotoPage
